import React, { Fragment, useEffect, useMemo, useState } from "react";
import Plotly from "plotly.js-finance-dist";
import createPlotlyComponent from "react-plotly.js/factory";
import { PartialDeep } from "type-fest";

import { KeyPerformanceIndicators, PlotlyDefaults, PerformanceKeyEnum, Language } from "../../../../components/src";

import { PeriodSelector } from "../../components/PeriodSelector";
import { InstrumentModelTypeEnum, Party, PartyAccountType, TradingManagerColumn } from "../../types.generated";
import { useGetPieDataQuery } from "./queries.generated";
import { keyBy } from "lodash";
import { useQueryState } from "../../../../components/src/use-query-state";
import { useProcessedData } from "./hooks";
import { SelectedRowType, ProcessedData } from "./interfaces";
import { usePrevious, useQueryArgs } from "../../../../components/src/common/Utils";
import { PerformanceTimeSeriesChart } from "../../components/PerformanceTimeSeriesChart";
import { usePerformance } from "./performanceGraphHooks";
import { excludeInAttribution, getTopBottomInstruments, getTopBottomInstrumentsFromSelectedRow, isValidRow } from "./functions";
import { AttributionGraph, AttributionSelectorEnum, PlotTypeEnum } from "./Attribution";
import { PrintButton } from "../../components/PrintButton";
import { Checkbox } from "../../../../components/src/form";

interface Props {
    client: PartialDeep<Party>;
    data: any;
    endDate: string;
}

const Plot = createPlotlyComponent(Plotly);

export function PerformanceTabSimple({ client, data, endDate }: Props): React.ReactElement {
    const [{ fetching: loading, error, data: paiData }] = useGetPieDataQuery({
        variables: { clientId: client._id, endDate: endDate },
        pause: client ? false : true,
        requestPolicy: "cache-and-network"
    });
    const [printView, setPrintView] = useQueryState("print", true);
    const [sumLevelPieChart, setSumLevelPieChart] = useQueryState("sumLevelPieChart", false);
    const [startDate] = useQueryState("startDate", client.firstTradeDate);
    const { pushQueryArgs: setFormData } = useQueryArgs();

    const processedData: ProcessedData = useProcessedData({ endDate, startDate, data });

    const [selectedRow, setSelectedRow] = useState<SelectedRowType>({
        name: "",
        performance: null,
        values: [],
        instrumentPerformances: [],
        benchmarks: [],
        riskFree: null,
        startDate: startDate,
        endDate: endDate,
        dateSeries: [],
        positionFlag: []
    });
    const previousSelectedRow: SelectedRowType = usePrevious(selectedRow);
    const previousSelectedClient: Partial<Party> = usePrevious(client);
    const { performanceSeries: _, alignedTimeSeries, value } = usePerformance({ ...selectedRow, endDate, startDate });
    const plotData: Partial<Plotly.PlotData> = useMemo(() => {
        if (!paiData || !client) return null;

        const accountsById = keyBy(client.accounts, "_id");
        const labels: string[] = [];
        const values: number[] = [];
        const positionByAccount: Record<string, TradingManagerColumn> = {};
        for (const column of paiData.tradingmanager) {
            let label = column.account.description;
            let accountId = column.accountId;
            if (sumLevelPieChart) {
                const account = accountsById[accountId];
                const parentAccount = accountsById[account.parentAccountId];
                label = parentAccount.description;
                accountId = account.parentAccountId;
            }
            labels.push(label);
            values.push(column.exposure);
            positionByAccount[accountId] = column as any;
        }

        return {
            labels: labels,
            values: values,
            textinfo: "percent",
            textposition: "inside",
            hovertemplate: "<extra></extra>%{label}<br>%{percent}",
            type: "pie",
            positionByAccount: positionByAccount
        };
    }, [client, paiData, sumLevelPieChart]);

    useEffect(() => {
        if (processedData) {
            const { firstSelectedRow, dateSeries } = processedData;

            const startDateAttribution =
                startDate > dateSeries[0] || startDate < dateSeries[dateSeries.length - 1] ? startDate : dateSeries[0];
            if (selectedRow.name === "") {
                setSelectedRow(firstSelectedRow);
                setFormData({ startDate: startDateAttribution, endDate });
            } else if (
                firstSelectedRow.name !== previousSelectedRow.name &&
                previousSelectedClient._id.toString() !== client._id.toString()
            ) {
                setSelectedRow(firstSelectedRow);
                setFormData({ startDate: startDateAttribution, endDate });
            } else if (selectedRow.name !== previousSelectedRow.name) {
                setFormData({ startDate: startDateAttribution, endDate });
            }
        }
    }, [client, endDate, previousSelectedClient, previousSelectedRow, processedData, selectedRow, setFormData, startDate]);

    const handlePrintView = (e) => {
        setPrintView(e.target.checked);
    };

    const handlePieChartLevel = (e) => {
        setSumLevelPieChart(e.target.checked);
    };

    if (!processedData) return <div></div>;

    if (!client.firstTradeDate) return <p>Missing firstTradeDate</p>;
    const { dataWithLevels } = processedData;

    if (loading)
        return (
            <div className="loader">
                <h3>Loading</h3>
            </div>
        );

    if (error)
        return (
            <div>
                <p>error</p>
                <pre> {JSON.stringify(error, null, 2)}</pre>
            </div>
        );

    const localLayout: Partial<Plotly.Layout> = {
        autosize: true,
        colorway: ["#43a04c", "#2186c5", "#ba46b8", "#009688", "#9e9e9e", "#e5878a"],
        showlegend: true,
        legend: {
            orientation: "v",
            xanchor: "auto",
            yanchor: "auto",
            xref: "container",
            yref: "container",
            x: 1,
            y: 1,
            font: {
                size: 10
            }
        },
        margin: {
            b: 0,
            l: 20,
            t: 20,
            r: 10
        }
    };
    const defaultLayout: Partial<Plotly.Layout> = PlotlyDefaults.getDefaultLayout();
    const thisLayout = PlotlyDefaults.mergeLayout(defaultLayout, localLayout);

    const localConfig: Partial<Plotly.Config> = {
        displayModeBar: false
    };

    const thisConfig: Partial<Plotly.Config> = PlotlyDefaults.mergeConfig(PlotlyDefaults.getDefaultConfig(), localConfig);

    let topBottomInstruments: string[] = [];
    if (selectedRow.name !== "" && selectedRow.instrumentPerformances.length) {
        topBottomInstruments = getTopBottomInstrumentsFromSelectedRow(selectedRow);
    } else {
        const defaultInstrumentsValues: Record<string, number> = {};
        for (const level of dataWithLevels) {
            if (
                level.instrument &&
                level.instrument.modelType &&
                !excludeInAttribution(level.instrument.modelType as unknown as InstrumentModelTypeEnum)
            ) {
                defaultInstrumentsValues[level.instrument.name] = level.series[level.series.length - 1];
            }
        }
        topBottomInstruments = getTopBottomInstruments(defaultInstrumentsValues);
    }

    const accountDescriptions: Record<string, number> = {};
    for (const row of dataWithLevels) {
        const account = processedData.accountsById[row.accountId];
        if (account && account.type === PartyAccountType.Physical && account.description) accountDescriptions[account.description] = 1;
    }

    const columnClass = printView ? "col-print-ls-6 col-pr-6 p-2" : "col-12 col-md-6";
    const rowHeight = printView ? "75mm" : "50vh";

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <h4>{selectedRow.name}</h4>
                </div>
                <div className="col d-print-none">
                    <div className="d-flex justify-content-end">
                        <PrintButton />
                    </div>
                </div>
            </div>

            <div className="row d-print-none">
                <div className="col">
                    <PeriodSelector firstAvailableStartDate={client.firstTradeDate} endDate={endDate}></PeriodSelector>
                    <label className="m-2">
                        <Checkbox checked={printView} onChange={handlePrintView} disabled={false} className="mt-2" /> {" Print view "}
                    </label>
                    <label className="m-2">
                        <Checkbox checked={sumLevelPieChart} onChange={handlePieChartLevel} disabled={false} className="mt-2" />
                        {" SumAccount Attribution "}
                    </label>
                </div>
            </div>

            <div className="row form-group">
                <div className={columnClass}>
                    <h4 className="text-center">Performance</h4>
                    {isValidRow(selectedRow) ? (
                        <PerformanceTimeSeriesChart timeseries={alignedTimeSeries} style={{ height: rowHeight }} showXlsxButton={false} />
                    ) : null}
                </div>
                <div className={columnClass}>
                    <h4 className="text-center">Key Performance Indicators (%)</h4>
                    {isValidRow(selectedRow) ? (
                        <KeyPerformanceIndicators
                            masterTimeSeriesName={selectedRow.name}
                            timeseries={alignedTimeSeries}
                            navValue={value}
                            riskFreeTimeSeries={selectedRow.riskFree}
                            locale={Language.En}
                            showItems={[
                                PerformanceKeyEnum.Value,
                                PerformanceKeyEnum.YearlyReturn,
                                PerformanceKeyEnum.Return,
                                PerformanceKeyEnum.Volatility,
                                PerformanceKeyEnum.SharpeRatio
                            ]}
                        />
                    ) : null}
                </div>
            </div>

            <div className="row form-group">
                <div className={columnClass}>
                    {selectedRow.performance ? (
                        <AttributionGraph
                            data={{
                                client,
                                performance: {
                                    dates: selectedRow.dateSeries,
                                    values: selectedRow.values,
                                    series: selectedRow.performance.__values,
                                    instrumentPerformances: selectedRow.instrumentPerformances as any
                                },
                                topBottomInstruments: topBottomInstruments,
                                accountDescriptions: Object.keys(accountDescriptions).sort(),
                                accountsById: processedData.accountsById
                            }}
                            selector={processedData ? processedData.attributionSelector : AttributionSelectorEnum.Instruments}
                            plotType={PlotTypeEnum.Waterfall}
                            style={{ height: rowHeight }}
                            client={client}
                            showForm={false}
                            showXlsxButton={false}
                            layout={{ showlegend: false, font: { size: 8 } }}
                            sumLevelAttribution={sumLevelPieChart}
                        />
                    ) : null}
                </div>
                <div className={columnClass}>
                    {plotData && Object.keys(plotData.positionByAccount).length > 1 ? (
                        <Fragment>
                            <h4 className="text-center">Portfolio</h4>
                            <Plot
                                data={[plotData]}
                                config={thisConfig}
                                layout={thisLayout}
                                style={{ height: rowHeight }}
                                useResizeHandler={true}
                            />
                        </Fragment>
                    ) : null}
                </div>
            </div>
        </>
    );
}
